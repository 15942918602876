


















import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'UOttawaTask1',
  inheritAttrs: false,
});
